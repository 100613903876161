(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/call-outs/table.tpl.html',
    '<table class="table table-hover table-responsive"><thead><tr><th>Title</th><th>Contexts</th><th>Type</th><th>Products</th><th>Start / End</th></tr></thead><tbody><tr ng-repeat="callOut in callOuts"><td>{{getFirst(callOut.title)}}<div class="row-actions"><a ui-sref="private.products.call-outs.edit({id: callOut.id})" title="Edit Call-Out Type" class="text-primary"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a> <a ng-click="delete(callOut)" href="javascript:void(0);" title="Delete Call-Out Type" class="text-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a></div></td><td>{{filterContexts(callOut.contexts)}}</td><td>{{callOut.type.name}}</td><td><abbr title="{{callOut.productSkus.join(\', \')}}">{{callOut.productSkus.length}}</abbr></td><td>{{callOut.start | date:\'dd-MM-yyyy\'}} - {{callOut.end | date:\'dd-MM-yyyy\'}}</td></tr></tbody></table>');
}]);
})();
