(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/login/login.tpl.html',
    '<div class="container"><form class="form-signin" role="form" ng-submit="process()"><h2 class="form-signin-heading">Please sign in</h2><div class="alert alert-warning" ng-show="message">{{message}}</div><input ng-model="model.Username" type="text" class="form-control" placeholder="Username" required="" autofocus=""> <input ng-model="model.Password" type="password" class="form-control" placeholder="Password" required=""> <label class="checkbox"><input type="checkbox" ng-model="model.Remember"> Remember me</label> <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button></form></div>');
}]);
})();
