(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/booking-details/modal/cancel.tpl.html',
    '<div class="booking-cancel"><button type="button" ng-click="close()" class="btn-icon booking-cancel__close"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewbox="0 0 16 16"><path fill="#181613" fill-rule="evenodd" d="M14.545 1.456a.417.417 0 0 1 0 .589l-12.5 12.5a.417.417 0 0 1-.59-.59l12.5-12.5a.417.417 0 0 1 .59 0Z" clip-rule="evenodd"></path><path fill="#181613" fill-rule="evenodd" d="M1.456 1.456a.417.417 0 0 1 .589 0l12.5 12.5a.417.417 0 0 1-.59.589l-12.5-12.5a.417.417 0 0 1 0-.59Z" clip-rule="evenodd"></path></svg></button><h4 class="booking-cancel__heading">Are you sure you want to cancel the booking?</h4><div class="booking-cancel__btns"><button type="button" class="btn btn-danger" ng-click="cancelBooking()" dgda-loader-button="" is-processing="isProcessing">Yes</button> <button type="button" class="btn btn-default" ng-click="close()" ng-disabled="isProcessing">No</button></div></div>');
}]);
})();
