(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/footerwidgets/footer-widgets.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Footer widgets</h1></div><h2 class="admin-page__section-title">Available footer widgets</h2><table class="table table-hover table.responsive" ng-show="widgets.length > 0"><thead><tr><th>Heading</th><th>&nbsp;</th><th>Language</th><th>Number of links</th><th>Sort order</th></tr></thead><tbody><tr ng-repeat="widget in widgets"><td>{{ widget.heading }}</td><td><div class="row-actions clearfix"><span ng-show="isProcessing" class="text-primary">Deleting...</span> <a ui-sref="private.settings.footer-widgets.edit({id: widget.id})" title="Edit Widget" class="text-primary"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a> <a ng-click="delete(widget.id)" href="javascript:void(0);" title="Delete widget" class="text-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a></div></td><td><img width="16" ng-src="/assets/images/flags/{{widget.languageCode.toLowerCase()}}.png"></td><td>{{ widget.footerLinks.length }}</td><td>{{ widget.sortOrder }}</td></tr></tbody></table></ui-view>');
}]);
})();
