(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/topbar-list/list.tpl.html',
    '<div class="topbar-list"><table class="table table-hover table-responsive topbars-list__table"><thead><tr><th></th><th>Type</th><th>Name</th><th>Start</th><th>End</th><th></th></tr></thead><tbody><tr ng-if="isLoading"><td colspan="6" class="text-center"><em>Loading topbars...</em></td></tr><tr ng-if="!isLoading && topbars.length < 1"><td colspan="6" class="text-center"><em>No topbars to show.</em></td></tr><tr ng-repeat="topbar in topbars track by topbar.id"><td><img width="16" ng-src="/assets/images/flags/{{topbar.languageCode.toLowerCase()}}.png"></td><td>{{ topbar.topBarType }}</td><td>{{ topbar.name }}</td><td><small>{{ topbar.start }}</small></td><td><small>{{ topbar.end }}</small></td><td width="140" class="topbar-list__btns"><span class="glyphicon glyphicon-refresh dgda-glyphicon-spin" ng-if="topbar.processing"></span> <a ng-click="edit(topbar)" class="glyphicon glyphicon-edit mr-3" ng-show="!topbar.processing"></a> <a ng-click="delete(topbar)" href="javascript:void(0)" class="glyphicon glyphicon-trash mr-3 text-danger" ng-show="!topbar.processing"></a></td></tr></tbody></table></div>');
}]);
})();
