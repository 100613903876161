(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/notifications/settings.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>Notifications</small></h1></div><h2>Slack Notifications</h2><form class="form-horizontal admin-page__form" role="form" ng-submit="saveSettings()"><div class="form-group"><label class="col-sm-5 col-md-3 control-label">Enable?</label><div class="col-sm-7 col-md-9"><label><input type="checkbox" ng-model="isEnabled" class="checkbox-inline"> Enable Slack Notifications</label></div></div><div class="form-group" ng-show="slackSettings.IsEnabled === \'true\'"><label class="col-sm-5 col-md-3 control-label">Channel</label><div class="col-sm-7 col-md-9"><input type="text" ng-model="slackSettings.Channel" class="form-control"></div></div><div class="form-group" ng-show="slackSettings.IsEnabled === \'true\'"><label class="col-sm-5 col-md-3 control-label">SKU Whitelist</label><div class="col-sm-7 col-md-9"><input type="text" ng-model="slackSettings.Skus" class="form-control"><p class="help-block">A comma-separated list of SKUs. If empty, all products will result in a notification.</p></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form>');
}]);
})();
