(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/products.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Supplier Codes</h1></div><table class="table table-hover table-responsive" ng-show="products.length > 0"><thead><tr><th>Sku</th><th>Variant Sku</th><th>Name</th><th>Codes</th><th>Used</th><th>Remaining</th></tr></thead><tbody><tr ng-repeat="product in products"><td>{{product.Sku}}</td><td>{{product.VariantSku}}</td><td>{{product.Name}}<div class="row-actions"><span class="text-muted"><a ui-sref="private.products.codes.supplier.codes-import({Id: product.Id})" class="text-success" title="Import new codes">Import codes</a></span></div></td><td>{{product.Codes}}</td><td><a ui-sref="private.products.codes.supplier.used-codes({Sku: product.Sku})">{{product.Codes-product.RemainingCodes}}</a></td><td><a ui-sref="private.products.codes.supplier.remaining-codes({Sku: product.Sku, VariantSku: product.VariantSku})">{{product.RemainingCodes}}</a></td></tr></tbody></table><p class="lead text-center" ng-if="product.length == 0">Awww :-( You should totally get some code products! :-)</p></ui-view>');
}]);
})();
