(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/mademyday/settings/parse/settings.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>Parse</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-horizontal admin-page__form" role="form" ng-submit="process()"><div class="form-group"><label for="inputGiftSeenMessage" class="col-sm-5 col-md-3 control-label">Gift Seen Message</label><div class="col-sm-7 col-md-9"><textarea class="form-control" id="inputGiftSeenMessage" ng-model="settings.SeenGiftMessage"></textarea><p class="help-text">[name] = Recipient name, [gift] = Gift name</p></div></div><div class="form-group"><label for="inputGiftSeenMessageAnon" class="col-sm-5 col-md-3 control-label">Gift Seen Message (anon)</label><div class="col-sm-7 col-md-9"><textarea class="form-control" id="inputGiftSeenMessageAnon" ng-model="settings.AnonymousSeenGiftMessage"></textarea><p class="help-text">[gift] = Gift name - this message is used, when no recipient name has been given.</p></div></div><div class="form-group"><label for="inputParseAppId" class="col-sm-5 col-md-3 control-label">Parse App Id</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputParseAppId" ng-model="settings.AppId"></div></div><div class="form-group"><label for="inputParseApiKey" class="col-sm-5 col-md-3 control-label">Parse API Key</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputParseApiKey" ng-model="settings.ApiKey"></div></div><div class="form-group"><label for="inputParseMasterKey" class="col-sm-5 col-md-3 control-label">Parse Master Key</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputParseMasterKey" ng-model="settings.MasterKey"></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form>');
}]);
})();
