(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/call-outs/list.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="page-header__title admin-page__header__title">Call-Outs</h1><div class="page-header__btns admin-page__header__btns"><a ui-sref="private.products.call-outs.add" class="btn btn-primary">Add new</a></div></div><h2>Active Call-Outs</h2><cp-ts-call-outs-table call-outs="activeCallOuts" on-action="refreshLists();"></cp-ts-call-outs-table><h2>Pending Call-Outs</h2><cp-ts-call-outs-table call-outs="pendingCallOuts" on-action="refreshLists();"></cp-ts-call-outs-table><h2>Inactive Call-Outs</h2><cp-ts-call-outs-table call-outs="inactiveCallOuts" on-action="refreshLists();"></cp-ts-call-outs-table></ui-view>');
}]);
})();
