(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/remainingcodes.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Product Remaining Codes</h1></div><div class="btn-group" role="group"><button class="btn btn-danger btn-sm" ng-disabled="!canDelete()" ng-click="deleteCodes()">Delete selected codes</button></div><div class="mt-2"><div class="alert alert-success" ng-if="deletedCodes.length > 0"><p>Deleted the following codes:</p><p ng-repeat="deletedCode in deletedCodes">{{deletedCode.Code}}</p></div><div class="alert alert-warning" ng-show="errorMessage">{{errorMessage}}</div></div><table class="table table-hover table-responsive" ng-show="productCodes.length > 0"><thead><tr><th></th><th>Created At</th><th>Code</th></tr></thead><tbody><tr ng-repeat="productCode in productCodes"><td><input type="checkbox" ng-model="productCode.isChecked"></td><td>{{productCode.CreatedAt | date:\'dd-MM-yyyy HH:mm\'}}</td><td>{{productCode.Code}}</td></tr></tbody></table></ui-view>');
}]);
})();
