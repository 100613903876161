(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/site/topbars/list.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Topbars</h1></div><div class="admin-page__section mb-6" ng-class="{\'is-topbar-loading\': processing}"><h2 class="admin-page__section-title">Running Topbars</h2><ts-topbar-list topbars="topbars.running" refresh="refresh()" is-loading="isLoading" is-processing="processing"></ts-topbar-list></div><div class="admin-page__section mb-6" ng-class="{\'is-topbar-loading\': processing}"><h2 class="admin-page__section-title">Ready Topbars</h2><ts-topbar-list topbars="topbars.ready" refresh="refresh()" is-loading="isLoading" is-processing="processing"></ts-topbar-list></div><div class="admin-page__section mb-6" ng-class="{\'is-topbar-loading\': processing}"><h2 class="admin-page__section-title">Paused Topbars</h2><ts-topbar-list topbars="topbars.paused" refresh="refresh()" is-loading="isLoading" is-processing="processing"></ts-topbar-list></div><div class="admin-page__section mb-6" ng-class="{\'is-topbar-loading\': processing}"><h2 class="admin-page__section-title">Ended Topbars</h2><ts-topbar-list topbars="topbars.ended" refresh="refresh()" is-loading="isLoading" is-processing="processing"></ts-topbar-list></div>');
}]);
})();
