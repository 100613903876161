(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/madklubben/create.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Create New Madklubben Code</h1><a ui-sref="private.products.codes.madklubben" class="btn btn-default">Back to list of codes</a></div><form ng-submit="handleCreate()" name="createForm"><div class="fetch-form-row"><div class="form-group input-group-lg"><label for="orderId">Add Order-ID</label> <input type="text" placeholder="Add ID here" class="form-control" required="" name="orderId" id="orderId" ng-model="order.orderNumber"></div><div class="form-group input-group-lg"><label for="orderValue ">Add code-value</label> <input type="number" placeholder="Add amount" class="form-control" required="" name="orderValue" id="orderValue" ng-model="order.amount"></div></div><button type="submit" class="btn btn-lg btn-success" ng-disabled="isProcessing">Create</button></form>');
}]);
})();
