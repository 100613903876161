(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/products/giftcardpreview/upload.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>Truestory Gift Card Preview Uploader</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><div ng-show="uploader.isHTML5"><div nv-file-drop="" uploader="uploader"><div class="well text-center text-muted" over-class="file-hover" nv-file-over="" uploader="uploader">drop your image here! (or select from your computer) <input type="file" nv-file-select="" class="center-block" uploader="uploader"></div></div><div class="text-center"><img ng-show="imageUrl" ng-src="{{imageUrl}}" width="300"><br>{{imageName}}</div><div ng-show="uploader.isUploading" class="progress" style="margin-bottom: 0;"><div class="progress-bar" role="progressbar" ng-style="{ \'width\': uploader.queue[0].progress + \'%\' }"></div></div></div>');
}]);
})();
