(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/campaigns/free-wrapping.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Free Wrapping</h1></div><form class="admin-page__form" role="form" ng-submit="save()"><div class="form-horizontal"><div class="form-group"><label class="col-sm-2 control-label" for="inputProduct">Products</label><div class="col-sm-10"><p ng-show="productSkus.length > 0" style="display: flex; gap: 8px;"><span ng-repeat="sku in productSkus" class="label label-default">{{sku}} <i style="cursor:pointer;" class="glyphicon glyphicon-trash" ng-click="removeProduct($index);"></i></span></p><div class="input-group"><input ng-model="selectedProduct" uib-typeahead="product as (product.sku + \' - \' + product.name) for product in products | filter:$viewValue | limitTo:8" class="form-control" id="inputProduct" typeahead-editable="false" typeahead-on-select="selectProduct($item, $model, $label, $event)"> <span class="input-group-addon"><i class="glyphicon" ng-class="{\'glyphicon-refresh spin-me-right-round\': loadingProducts, \'glyphicon-ok\': !loadingProducts}"></i></span></div></div></div><div class="form-group"><div class="col-sm-offset-2 col-sm-10"><button type="submit" class="btn btn-success" dgda-loader-button="" is-processing="isSaving">Save</button></div></div></div></form>');
}]);
})();
