(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/campaigns/campaigns.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Campaigns</h1><div class="page-header__btns admin-page__header__btns"><a ui-sref="private.campaigns.add" class="btn btn-primary">Add new</a></div></div><div class="admin-page__section"><h2 class="admin-page__section-title">Running Campaigns</h2><cp-campaign-list campaigns="campaigns.running" show-actions="true" refresh="refresh();"></cp-campaign-list></div><div class="admin-page__section"><h2 class="admin-page__section-title">Ready Campaigns</h2><cp-campaign-list campaigns="campaigns.ready" show-actions="true" refresh="refresh();"></cp-campaign-list></div><div class="admin-page__section"><h2 class="admin-page__section-title">Paused Campaigns</h2><cp-campaign-list campaigns="campaigns.paused" show-actions="true" refresh="refresh();"></cp-campaign-list></div><div class="admin-page__section"><h2 class="admin-page__section-title">Ended Campaigns</h2><cp-campaign-list campaigns="campaigns.ended" show-actions="false" refresh="refresh();"></cp-campaign-list></div></ui-view>');
}]);
})();
