(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/madklubben/cancel.tpl.html',
    '<div class="modal-cancel-code"><div class="modal-header"><a class="close" ng-click="close()" aria-label="Close"><span aria-hidden="true">&times;</span></a></div><div class="modal-body"><h4 class="modal-title">Cancel code</h4><p>Are you sure you wish to cancel the code?</p><div class="modal-button-group"><button type="button" ng-click="close()" class="btn btn-outline btn-rounded">No</button> <button type="button" ng-click="cancelCode()" class="btn btn-warning btn-rounded">Yes</button></div></div></div>');
}]);
})();
