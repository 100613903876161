(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/tiles/views/normalTile.tpl.html',
    '<div class="form-group"><label for="inputTitle" class="col-sm-5 col-md-3 control-label">Title</label><div class="col-sm-7 col-md-9"><input required="" type="text" class="form-control" id="inputTitle" placeholder="Title" ng-model="tile.title"></div></div><div class="form-group"><label for="inputLink" class="col-sm-5 col-md-3 control-label">Link</label><div class="col-sm-7 col-md-9"><input required="" type="text" class="form-control" id="inputLink" placeholder="Link" ng-model="tile.link"></div></div><div class="form-group"><label for="inputImage" class="col-sm-5 col-md-3 control-label">Image URL</label><div class="col-sm-7 col-md-9"><input required="" type="text" class="form-control" id="inputImage" placeholder="Image URL" ng-model="tile.image"></div></div>');
}]);
})();
