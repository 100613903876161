(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/settings.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings</h1></div></ui-view>');
}]);
})();
