(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dashboard/dashboard.tpl.html',
    ':-)');
}]);
})();
