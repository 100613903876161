(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/tiles/filter-directive/filter.tpl.html',
    '<div class="tile-filter"><h4 class="text-capitalize">{{pluralname}}</h4><div class="form-group"><div class="input-group"><select class="form-control" id="inputCategory" ng-options="item.name for item in (items | orderBy: \'name\') track by item.id" ng-model="selected"><option value="">-- Select {{name}} --</option></select><span class="input-group-btn"><button class="btn btn-default" type="button" ng-click="add()">Add</button></span></div></div><div class="collections tile-filter__selected"><span class="label label-default collection-label" ng-repeat="item in added | orderBy">{{item}} <span class="remove" ng-click="remove(item);">&times;</span></span></div></div>');
}]);
})();
