(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/mademyday/products/filters/filters.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Filters <small>by city</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-horizontal admin-page__form" role="form" ng-submit="process()" name="mmdFiltersForm"><div class="form-group"><label for="inputCities" class="col-sm-5 col-md-3 control-label">Cities</label><div class="col-sm-7 col-md-9"><textarea class="form-control" id="inputCities" placeholder="Cities (one per line)" ng-model="model.Cities"></textarea></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form>');
}]);
})();
