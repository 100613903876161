(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/orders/order-expiration.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Orders <small>Expiration</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-horizontal admin-page__form" role="form" name="orderExpirationForm" ng-submit="process()"><div class="form-group has-feedback row"><label for="inputOrderNumber" class="col-sm-4 control-label">Order Number</label><div class="col-sm-8"><input required="required" ng-blur="getExpiration();" type="text" class="form-control" ng-model="model.OrderNumber" placeholder="WEB-XXXX" aria-describedby="basic-addonOrderNumber" id="inputOrderNumber"> <span ng-show="isRefreshing" class="glyphicon glyphicon-refresh dgda-glyphicon-spin form-control-feedback" aria-hidden="true"></span></div></div><div class="form-group row"><label for="inputExpirationDate" class="col-sm-4 control-label">Expiration Date</label><div class="col-sm-8"><input required="required" type="date" ng-model-options="{ timezone: \'UTC\' }" class="form-control" ng-model="model.ExpirationDate" placeholder="dd-mm-yyyy" aria-describedby="basic-addonOrderNumber" id="inputExpirationDate"></div></div><div class="form-group"><div class="col-sm-3 col-md-offset-9"><button type="submit" class="btn btn-success btn-lg" dgda-loader-button="" is-processing="isProcessing" ng-disabled="orderExpirationForm.$invalid || isProcessing" style="min-width: 180px;">Update Expiration</button></div></div></form>');
}]);
})();
