(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/rankings/rankings.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Products <small>Rankings</small></h1></div><h2>Settings</h2><form class="admin-page__form form-horizontal" role="form" ng-submit="processSettings()"><div class="form-group"><label for="inputRankingsUrl" class="col-sm-5 col-md-3 control-label">Ranking Engine URL</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputRankingsUrl" placeholder="URL to Ranking Engine API" ng-model="settings.ApiUrl"></div></div><div class="form-group"><label for="inputRankingsApiKey" class="col-sm-5 col-md-3 control-label">API Key</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputRankingsApiKey" placeholder="Ranking Engine API Key" ng-model="settings.ApiKey"></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form><div ng-if="showLists"><h2>Ranking Lists</h2><form class="form-horizontal admin-page__form" role="form" ng-submit="processLists()"><div class="form-group" ng-repeat="(key, value) in lists" ng-if="rankings.length > 0"><label class="control-label col-sm-5 col-md-3">{{ (languages | filter : {\'languageCode\': key})[0].name }} (<img width="16" ng-src="/assets/images/flags/{{key.toLowerCase()}}.png">)</label><div class="col-sm-7 col-md-9"><select class="form-control" ng-model="lists[key]" ng-options="(ranking.id + \'\') as ranking.name for ranking in rankings"></select></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form></div>');
}]);
})();
