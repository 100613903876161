(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/settings/faq/faq.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>FAQ Subjects</small></h1><div class="admin-page__header__btns"><button type="button" class="btn btn-primary" ui-sref="private.settings.faqadd"><span class="glyphicon glyphicon-plus-sign" aria-hidden="true"></span> Add subject</button></div></div><table class="table table-striped"><thead><tr><th>Order</th><th>Subject</th><th></th></tr></thead><tbody><tr ng-repeat="subject in subjects"><td>{{subject.Order}}</td><td>{{subject.Text}}</td><td><a ui-sref="private.settings.faqedit({SubjectId: subject.Id})"><span class="glyphicon glyphicon-pencil"></span></a> <a ng-click="deleteSubject(subject.Id)"><span class="glyphicon glyphicon-trash"></span></a></td></tr></tbody></table>');
}]);
})();
