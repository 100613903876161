(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/orders/custom-properties.tpl.html',
    '<h3 class="mb-4">Personal greeting on gift-card</h3><div class="form-group"><label for="inputPersonalizedDate" class="control-label">Occasion</label><div class="mt-1"><input id="inputPersonalizedDate" class="form-control" ng-model="order.Personalization.Date" placeholder="Occasion"></div></div><div class="form-group"><label for="inputPersonalizedMessage" class="control-label">Message</label><div class="mt-1"><textarea id="inputPersonalizedMessage" class="form-control" ng-model="order.Personalization.Message" placeholder="Message"></textarea></div></div><div class="form-group"><label for="inputPersonalizedName" class="control-label">Sender</label><div class="mt-1"><input id="inputPersonalizedName" class="form-control" ng-model="order.Personalization.Name" placeholder="Sender"></div></div>');
}]);
})();
