(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/mademyday/products/review-data/reviewdata.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Export <small>Review Data</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-inline" role="form" ng-submit="process()" name="mmdReviewDataForm"><div class="form-group"><label for="inputFrom" class="col-sm-5 col-md-3 control-label">From</label><div class="col-sm-7 col-md-9"><input class="form-control" id="inputFrom" ng-model="model.from" placeholder="dd-mm-yyyy"></div></div><div class="form-group"><label for="inputTo" class="col-sm-5 col-md-3 control-label">To</label><div class="col-sm-7 col-md-9"><input class="form-control" id="inputTo" ng-model="model.to" placeholder="dd-mm-yyyy"></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success" ng-disabled="isProcessing">Get Data</button></div></div></form><div ng-show="data.length > 0"><table class="table"><thead><tr><th>Order Number</th><th>Product</th><th>Name</th><th>Phone</th><th>Review Date</th><th>Location</th></tr></thead><tbody><tr ng-repeat="d in data"><td>{{d.OrderNumber}}</td><td>{{d.ProductName}}</td><td>{{d.Name}}</td><td>{{d.Phone}}</td><td>{{d.Date | date:\'dd-MM-yyyy HH:mm\'}}</td><td>{{d.ProductLocation}}</td></tr></tbody></table></div>');
}]);
})();
