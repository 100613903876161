(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/settings/faq/subject.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Add new subject</h1></div><div class="row"><div class="col-sm-12"><form class="form-horizontal admin-page__form" role="form" ng-submit="process()"><div class="form-group"><label class="col-sm-2 control-label" for="subjectText">Subject</label><div class="col-sm-3"><input type="text" class="form-control" id="subjectText" placeholder="Subject" ng-model="subject.Text"></div></div><div class="form-group"><label class="col-sm-2 control-label" for="subjectOrder">Order</label><div class="col-sm-3"><input type="number" class="form-control" id="subjectOrder" placeholder="Order" min="1" step="1" ng-model="subject.Order"></div></div><div class="form-group"><div class="col-sm-offset-2 col-sm-10"><button type="submit" class="btn btn-primary">Submit</button></div></div></form></div></div>');
}]);
})();
