(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/campaign-list/list.tpl.html',
    '<div class="campaign-list"><p ng-hide="campaigns.length > 0"><em>No campaigns to show...</em></p><table class="table table-hover table-responsive campaign-list-table" ng-show="campaigns.length > 0" ng-class="{\'skeleton-item\': loading}"><thead><tr><th></th><th>Name</th><th>&nbsp;</th><th>Start</th><th>End</th><th>&nbsp;</th></tr></thead><tbody><tr ng-repeat="campaign in campaigns | orderBy:\'priority\'"><td class="text-center"><img ng-src="/assets/images/campaigns/{{campaign.campaignType}}.svg" height="16"></td><td>{{campaign.name}}</td><td class="campaign-list-table__btns"><div class="row-actions" ng-show="showActions"><a ui-sref="private.campaigns.edit({id: campaign.id})" title="Edit campaign" class="text-primary"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a> <a ng-click="delete(campaign.id)" href="javascript:void(0);" title="Delete campaign" class="text-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a> <a ng-click="prioritize(campaign.id, -1)" href="javascript:void(0);" title="Move up" class="text-primary"><span class="glyphicon glyphicon-arrow-up" aria-hidden="true"></span></a> <a ng-click="prioritize(campaign.id, 1)" href="javascript:void(0);" title="Move up" class="text-primary"><span class="glyphicon glyphicon-arrow-down" aria-hidden="true"></span></a></div></td><td><small>{{campaign.start}}</small></td><td><small>{{campaign.end}}</small></td><td><img width="16" ng-src="/assets/images/flags/{{campaign.languageCode.toLowerCase()}}.png"></td></tr></tbody></table></div>');
}]);
})();
