(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/orders/refunds/manualrefund.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Manual refund</h1></div><div><h2>Please enter where the money are transfered to</h2><form class="form-horizontal" ng-submit="update()"><div class="form-group"><label for="inputRegNumber" class="col-sm-2 control-label">Reg Number</label><div class="col-sm-8"><input required="" placeholder="Reg number" type="text" class="form-control" id="inputRegNumber" ng-model="refund.regNumber"></div></div><div class="form-group"><label for="inputAccountNumber" class="col-sm-2 control-label">Account Number</label><div class="col-sm-8"><input required="" placeholder="Account number" type="text" class="form-control" id="inputAccountNumber" ng-model="refund.accountNumber"></div></div><div class="form-group"><button type="submit" class="btn btn-success">Confirm and save</button></div><div class="form-group"><span ng-show="infoMessage.length > 0" ng-class="{\'alert alert-info\': !hasError, \'alert alert-danger\': hasError }" role="alert">{{infoMessage}} <span style="cursor: pointer;" ng-click="infoMessage = null">&times;</span></span></div></form></div>');
}]);
})();
