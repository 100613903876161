(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/notifications/template-settings/edit.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Edit template</h1></div><form id="notificationTemplateForm" ng-submit="onSubmit()"><div class="form-group"><label class="col-sm-5 col-md-3 control-label">Name</label><div class="col-sm-7 col-md-9"><p class="form-control-static">{{model.name}}</p></div></div><div class="form-group"><label class="col-sm-5 col-md-3 control-label">Description</label><div class="col-sm-7 col-md-9"><p class="form-control-static">{{model.description}}</p></div></div><div class="form-group"><label for="inputTemplate" class="col-sm-5 col-md-3 control-label">Template*</label><div class="col-sm-12"><textarea required="" id="inputTemplate" rows="25" class="form-control" ng-model="model.template"></textarea></div></div><div class="form-group"><button type="submit" class="btn btn-success">Update</button></div><div class="form-group"><span ng-show="infoMessage.length > 0" ng-class="{\'alert alert-info\': !hasError, \'alert alert-danger\': hasError }" role="alert">{{infoMessage}} <span style="cursor: pointer;" ng-click="infoMessage = null">&times;</span></span></div></form>');
}]);
})();
