(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/orders/redeem/supplier-list.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Redeem <small>Supplier List</small></h1></div><div class="text-center" ng-show="isLoading">Please wait, we\'re loading the data... (it takes a while)</div><div ng-show="supplierInfo.length > 0"><table class="table"><thead><tr><th ng-class="{\'active\': currentPredicate == \'SupplierName\'}"><a href="" ng-click="setOrderBy(\'SupplierName\')">Supplier</a></th><th ng-class="{\'active\': currentPredicate == \'Sales\'}"><a href="" ng-click="setOrderBy(\'Sales\')"># Sales</a></th><th ng-class="{\'active\': currentPredicate == \'Redeemed\'}"><a href="" ng-click="setOrderBy(\'Redeemed\')"># Redeemed</a></th><th ng-class="{\'active\': currentPredicate == \'RedeemedThisWeek\'}"><a href="" ng-click="setOrderBy(\'RedeemedThisWeek\')">Redeemed this week</a></th><th ng-class="{\'active\': currentPredicate == \'RedeemedLastWeek\'}"><a href="" ng-click="setOrderBy(\'RedeemedLastWeek\')">Redeemed last week</a></th><th ng-class="{\'active\': currentPredicate == \'LastRedeemed\'}"><a href="" ng-click="setOrderBy(\'LastRedeemed\')">Last Redeemed</a></th></tr></thead><tbody><tr ng-repeat="info in supplierInfo | orderBy:orderByPredicate"><td>{{info.SupplierName}}</td><td>{{info.Sales}}</td><td>{{info.Redeemed}}</td><td>{{info.RedeemedThisWeek}}</td><td>{{info.RedeemedLastWeek}}</td><td>{{info.LastRedeemed | date:\'dd-MM-yyyy\'}}</td></tr></tbody></table></div>');
}]);
})();
