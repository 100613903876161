(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/settings/ease/settings.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>ease booking</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-horizontal admin-page__form" role="form" ng-submit="process()"><div class="form-group"><label for="inputApiUrl" class="col-sm-5 col-md-3 control-label">Ease Booking API URL</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputApiUrl" placeholder="API URL" ng-model="model.ApiUrl" disabled=""></div></div><div class="form-group"><label for="inputApiKey" class="col-sm-5 col-md-3 control-label">API Key</label><div class="col-sm-7 col-md-9"><input type="text" class="form-control" id="inputApiKey" placeholder="API Key" ng-model="model.ApiKey" disabled=""></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form>');
}]);
})();
