(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/tiles/views/productTile.tpl.html',
    '<div class="form-group"><label for="inputTitle" class="col-sm-5 col-md-3 control-label">Product</label><div class="col-sm-7 col-md-9"><input required="" type="text" uib-typeahead="product.sku as product.sku + \' (\' + product.name +\')\' for product in products | filter: $viewValue" typeahead-editable="false" class="form-control" id="inputTitle" placeholder="Sku or product name" ng-model="tile.title"></div></div>');
}]);
})();
