(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/usedcodes.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="admin-page__header__title">Product Used Codes</h1></div><table class="table table-hover table-responsive" ng-show="orders.length > 0"><thead><tr><th>Order Id</th><th>Used At</th><th>Code</th><th></th></tr></thead><tbody><tr ng-repeat="order in orders"><td>{{order.Reference}}</td><td>{{order.UsedAt | date:\'dd-MM-yyyy HH:mm\'}}</td><td>{{order.Code}}</td><td><button type="button" class="btn btn-primary" ng-click="revoke(order, sku)">Revoke Code</button></td></tr></tbody></table></ui-view>');
}]);
})();
