(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/products/codes/import.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Events <small>Import codes</small></h1></div><div class="alert" role="alert" ng-class="\'alert-\' + alert.Type" ng-show="alert.Show">{{alert.Message}}</div><form class="form-horizontal admin-page__form" role="form" ng-submit="process()"><div class="form-group"><label for="textareaCodes" class="col-sm-5 col-md-3 control-label">Codes</label><div class="col-sm-7 col-md-9"><textarea class="form-control" id="textareaCodes" placeholder="Add codes here (one per line)" ng-model="model.Codes" required=""></textarea></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Import codes</button></div></div></form>');
}]);
})();
