(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/call-outs/list.tpl.html',
    '<ui-view><div class="page-header admin-page__header"><h1 class="page-header__title admin-page__header__title">Call-Out Types</h1><div class="page-header__btns admin-page__header__btns"><a ui-sref="private.settings.call-out-types.add" class="btn btn-primary">Add new</a></div></div><style>\n' +
    '    /* This should be added as global */\n' +
    '    .ts-icon {\n' +
    '        display: inline-block;\n' +
    '        fill: black;\n' +
    '        stroke: black;\n' +
    '        stroke-width: 0;\n' +
    '    }\n' +
    '</style><table class="table table-hover table-responsive"><thead><tr><th></th><th>Name</th><th></th></tr></thead><tbody><tr ng-repeat="callOutType in callOutTypes | orderBy:\'name\'"><td class="text-center" ng-attr-style="border-left: 3px solid {{callOutType.color}}"><svg width="24" height="24" class="ts-icon" ng-class="callOutType.icon" ng-if="callOutType.icon.length > 0"><use xlink:href="" ng-attr-xlink:href="{{sce.getTrustedResourceUrl(\'/assets/ts-icons/svg/ts-icons.svg#\' + callOutType.icon)}}"></use></svg></td><td>{{callOutType.name}}<div class="row-actions"><a ui-sref="private.settings.call-out-types.edit({id: callOutType.id})" title="Edit Call-Out Type" class="text-primary"><span class="glyphicon glyphicon-pencil" aria-hidden="true"></span></a> <a ng-click="delete(callOutType)" href="javascript:void(0);" title="Delete Call-Out Type" class="text-danger"><span class="glyphicon glyphicon-trash" aria-hidden="true"></span></a></div></td></tr></tbody></table></ui-view>');
}]);
})();
