(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/notifications/notification-preview.tpl.html',
    '<iframe srcdoc="{{iframeSrc}}" class="preview-notification"></iframe>');
}]);
})();
