(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/dgda/products/rankingweights/weights.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Products <small>Ranking Factor Weights</small></h1></div><form class="form-horizontal admin-page__form" role="form" ng-submit="process()"><div class="form-group" ng-repeat="weight in weights"><label ng-attr-for="inputWeightFactor-{{weight.Name}}" class="col-sm-5 col-md-3 control-label">{{weight.Name}}</label><div class="col-sm-7 col-md-9"><input type="number" step="0.1" min="0" max="100" class="form-control" ng-attr-id="inputWeightFactor-{{weight.Name}}" ng-model="weight.Weight"></div></div><div class="form-group"><div class="col-sm-offset-5 col-sm-7 col-md-offset-3 col-md-9"><button type="submit" class="btn btn-success">Save</button></div></div></form>');
}]);
})();
