(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/settings/notifications/template-settings/templates-email.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Settings <small>Email Templates</small></h1></div><table class="admin-page__section table table-hover table-responsive"><thead><tr><th>&nbsp;</th><th>Name</th><th>Description</th><th>Languages</th><th></th><th></th></tr></thead><tbody><tr ng-repeat="nt in notificationTemplates | filter: {type: 1} | orderBy: \'name\'"><th><img width="16" ng-src="/assets/images/notifications/email.svg" alt="{{nt.type.toUpperCase()}}"></th><td>{{nt.name}}<br><small class="text-muted">{{nt.slug}}</small></td><td>{{nt.description}}</td><td><span ng-if="nt.languageCodes"><span ng-repeat="lang in nt.languageCodes"><img width="16" ng-src="/assets/images/flags/{{lang.toLowerCase()}}.png" alt="{{lang}}" ng-if="lang.toLowerCase() !== \'none\'"></span></span></td><td><button class="btn btn-small btn-default" ui-sref="private.settings.notifications.templates.edit({id: nt.id})">Edit</button></td><td><button class="btn btn-small btn-default" ng-click="delete(nt)">Delete</button></td></tr></tbody></table>');
}]);
})();
