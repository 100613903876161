(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/mademyday/orders/delivery/delivery.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Export <small>Delivery Data</small></h1></div><table class="table"><thead><tr><th ng-repeat="dayData in days" class="text-center">{{dayData.Date | date:\'dd-MM\'}}</th></tr></thead><tbody><tr><td ng-repeat="dayData in days" class="text-center"><a href="#" ng-click="loadData(dayData);">{{dayData.Orders}}</a></td></tr></tbody></table><div ng-show="data.length > 0"><h2>Data</h2><table class="table"><thead><tr><th>Order #</th><th>Supplier</th><th>Product</th><th>Name</th><th>Address</th><th>Postcode</th><th>City</th><th>Phone</th><th>Date</th></tr></thead><tbody><tr ng-repeat="order in data"><td>{{order.OrderNumber}}</td><td>{{order.Supplier}}</td><td>{{order.Quantity}}x {{order.ProductName}}</td><td>{{order.DeliveryName}}</td><td>{{order.DeliveryAddress}}</td><td>{{order.DeliveryPostcode}}</td><td>{{order.DeliveryCity}}</td><td>{{order.Phone}}</td><td>{{order.DeliveryDate | date:\'dd-MM-yyyy\'}}</td></tr></tbody></table><div class="alert alert-success" ng-show="exported.Url">Your data has been exported! It can be found here: <a ng-href="{{exported.Url}}" target="_blank">{{exported.Url}}</a></div><form class="form-inline"><div class="form-group form-group-lg"><select ng-model="exportData.Supplier" class="form-control input-lg" ng-options="supplier for supplier in suppliers"><option value="">-- Pick suppliers--</option></select></div><button ng-click="exportCsv()" class="btn btn-lg btn-default">Export to CSV</button> <button ng-click="exportPdf()" class="btn btn-lg btn-default">Export PDF Cards</button></form></div>');
}]);
})();
