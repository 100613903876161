(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('directives/localized-input/input.tpl.html',
    '<div class="localized-input"><p ng-show="selectedLanguage == null" class="form-control-static"><em>Add some languages, please! :-)</em></p><div class="input-group" ng-show_="selectedLanguage != null"><div class="input-group-btn"><button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img ng-src="/assets/images/flags/{{selectedLanguage.languageCode}}.png" width="16"> <span class="caret"></span></button><ul class="dropdown-menu"><li ng-repeat="language in languages"><button type="button" class="btn btn-link" ng-click="setLanguage(language);"><img ng-src="/assets/images/flags/{{language.languageCode}}.png" width="16"> {{language.name}}</button></li></ul></div><input ng-repeat="language in languages" ng-show="selectedLanguage.languageCode == language.languageCode" ng-required="ngRequired" ng-attr-type="{{type}}" ng-maxlength="{{maxlength}}" name="{{name}}-{{selectedLanguage.languageCode}}" ng-model="model[selectedLanguage.languageCode]" class="form-control"></div></div>');
}]);
})();
