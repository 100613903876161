(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/search/search.tpl.html',
    '<div class="page-header admin-page__header"><h1 class="admin-page__header__title">Search</h1></div><form name="searchForm" ng-submit="doSearch();"><div class="input-group input-group-lg"><input type="text" class="form-control" placeholder="Search for..." ng-model="search.Query"> <span class="input-group-btn"><button class="btn btn-default" type="submit" ng-disabled="isSearching">Go!</button></span></div><div class="help-text text-muted mt-1"><small>To search by bulkid prefix it with: <em>bulkid-</em></small></div><p ng-show="searchForm.$submitted" class="mt-2">Orders found: {{search.Results.length}}</p></form><table class="table table-striped"><thead><tr><th>Order#</th><th>Customer</th><th>Email</th><th>Code / Codes</th><th>Status</th><th>Total</th><th></th></tr></thead><tbody><tr ng-if="!isSearching && (!search.Results || (search.Results && search.Results.length === 0))"><td colspan="7" style="text-align:center;"><em>Nothing to show...</em></td></tr><tr ng-if="isSearching"><td colspan="7" style="text-align:center;"><em>Loading...</em></td></tr><tr ng-repeat="order in search.Results" ng-if="order.OrderNumber"><td>{{order.OrderNumber}}</td><td>{{order.Customer.FullName}}</td><td>{{order.Customer.Email}}</td><td><span ng-show="order.BookingCode">{{order.BookingCode}}</span> <span ng-show="order.VoucherCode">{{order.VoucherCode}}</span></td><td>{{order.Status}}</td><td>{{order.Total | dkk}}</td><td><a ui-sref="private.search-details({orderNumber: order.OrderNumber})"><span class="glyphicon glyphicon-info-sign text-info"></span></a> <a ui-sref="private.orders.edit.dgda({OrderNumber: order.OrderNumber})"><span class="glyphicon glyphicon-pencil"></span></a></td></tr></tbody></table>');
}]);
})();
