(function(module) {
try {
  module = angular.module('dgda-cockpit.Module.Templates');
} catch (e) {
  module = angular.module('dgda-cockpit.Module.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('states/abstracts/private.tpl.html',
    '<div class="navbar navbar-inverse navbar-fixed-top" role="navigation" ng-controller="PrimaryNavigationController"><div class="container-fluid"><div class="navbar-header"><button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"><span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span></button> <a class="navbar-brand" href="#">Truestory Cockpit</a></div><div class="navbar-collapse collapse"><ul class="nav navbar-nav navbar-right"><li ng-class="{\'active\': isActiveState(item.State)}" ng-repeat="item in navigation"><a href="{{getUrl(item.State)}}">{{item.Label}}</a></li></ul></div></div></div><div class="container-fluid"><div class="row"><div class="col-sm-3 col-md-2 sidebar" ng-controller="SidebarNavigationController"><ul class="nav nav-sidebar" ng-repeat="group in navigation"><li ng-if="group.Name"><strong>{{group.Name}}</strong></li><li ng-class="{\'active\': isActiveState(item.State)}" ng-repeat="item in group.Items"><a href="{{getUrl(item.State)}}">{{item.Label}}</a></li><li ng-if="group.Name === \'Orders\'"><a href="/v2/admin/booking-requests/">Pending Requests</a></li></ul></div><div class="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2 main admin-page" ui-view=""></div></div></div>');
}]);
})();
